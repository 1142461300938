// extracted by mini-css-extract-plugin
export var academiaCard = "Academia-module--academiaCard--zwSDq";
export var applyBtn = "Academia-module--applyBtn--8N435";
export var buttons = "Academia-module--buttons--WAzJS";
export var container = "Academia-module--container--mzev3";
export var img = "Academia-module--img--Gbl7q";
export var info = "Academia-module--info--V6xWC";
export var item = "Academia-module--item--u2eEm";
export var limitWidthOnLargeScreens = "Academia-module--limitWidthOnLargeScreens--34wIA";
export var main = "Academia-module--main--irbSF";
export var rows = "Academia-module--rows--N6RmR";
export var signUpBtn = "Academia-module--signUpBtn--Ha+7l";
export var whatIsIncluded = "Academia-module--whatIsIncluded--buSXo";
export var wrapper = "Academia-module--wrapper--++efi";