import { Button, Typography } from "@components/index";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./Academia.module.scss";
import Icon, { IconNames } from "./Icon";

const whoIsElegible = [
	"New to paid Genemod plan",
	"All users required to use an .edu or equivalent email address",
];

const whatIsIncluded: { icon: IconNames; name: string }[] = [
	{
		icon: "people",
		name: "10 users",
	},
	{
		icon: "consumables",
		name: "Consumables",
	},
	{
		icon: "advanced-search",
		name: "Advanced search",
	},
	{
		icon: "freezer",
		name: "Inventory",
	},
	{
		icon: "protocol-library",
		name: "Protocol library",
	},
	{
		icon: "stock-tracker",
		name: "Stock tracker",
	},
	{
		icon: "notebook",
		name: "Notebook",
	},
	{
		icon: "edit",
		name: "Custom item types",
	},
];

const Academia = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.academiaCard}>
				<div className={styles.main}>
					<Typography
						variant="HEADER2"
						color="marine"
						style={{ fontWeight: "bold", marginBottom: 8 }}
					>
						Academia
					</Typography>
					<Typography
						style={{
							marginBottom: 20,
						}}
						variant="SUBHEADERDESC"
						color="text-dark"
					>
						Designed for academic research labs looking to scale at
						a fraction of the cost
					</Typography>
					<div className={styles.buttons}>
						<Button
							className={styles.applyBtn}
							link="APPLICATION_ACADEMIA"
							target="_blank"
						>
							Apply now
						</Button>
						<Button
							className={styles.signUpBtn}
							link="SIGN_UP"
							type="ghost"
							target="_blank"
						>
							Sign up free
						</Button>
					</div>
				</div>
				<div className={styles.info}>
					<div className={styles.container}>
						<Typography
							color="text-dark"
							variant="SUBTITLE"
							style={{ fontWeight: 600, marginBottom: 20 }}
						>
							Who is eligible:
						</Typography>
						<div className={styles.rows}>
							{whoIsElegible.map((item, index) => (
								<div key={index} className={styles.item}>
									<span style={{ width: 24, height: 24 }}>
										<svg
											id="pricing_checkmark"
											data-name="pricing checkmark"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 32 32"
										>
											<rect
												id="Rectangle_9961"
												data-name="Rectangle 9961"
												width="32"
												height="32"
												fill="rgba(255,255,255,0)"
											/>
											<path
												id="Icon_metro-checkmark"
												data-name="Icon metro-checkmark"
												d="M17.758,5.784,9.321,14.222,5.383,10.284,2.571,13.1l6.75,6.75L20.571,8.6Z"
												transform="translate(4.93 2.216)"
												fill="#000"
											/>
										</svg>
									</span>
									<Typography color="text-dark">
										{item}
									</Typography>
								</div>
							))}
						</div>
					</div>
					<div className={styles.container}>
						<Typography
							color="text-dark"
							variant="SUBTITLE"
							style={{ fontWeight: 600, marginBottom: 20 }}
						>
							What is included:
						</Typography>
						<div className={styles.whatIsIncluded}>
							{whatIsIncluded.map(({ name, icon }, index) => (
								<div key={index} className={styles.item}>
									<Icon name={icon} />
									<Typography color="text-dark">
										{name}
									</Typography>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<Button style={{ marginTop: 36 }} link="ACADEMIA" type="link">
				Learn more
			</Button>
		</div>
	);
};

export default Academia;
